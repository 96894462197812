@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?euf1s0');
  src:  url('fonts/icomoon.eot?euf1s0#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?euf1s0') format('truetype'),
    url('fonts/icomoon.woff?euf1s0') format('woff'),
    url('fonts/icomoon.svg?euf1s0#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  font-family: 'icomoon' !important;
  background: transparent !important;
  text-transform: none;
  line-height: 1;
}

.icon-tt:before {
  content: "\e900";
}
.icon-vk:before {
  content: "\e901";
}
.icon-tg:before {
  content: "\e902";
}
.icon-fb:before {
  content: "\e905";
}
.icon-ok:before {
  content: "\e906";
}
.icon-yt:before {
  content: "\e907";
}
.icon-ig:before {
  content: "\e908";
}